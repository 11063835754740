<template>
  <landing-page />
</template>

<script>
  import LandingPage from '../components/LandingPage'

  export default {
    name: 'Home',

    components: {
      LandingPage,
    },
  }
</script>
